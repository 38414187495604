import clsx from 'clsx'

import useLocale from '@hooks/client/useLocale'

import styles from './login-disclaimer.module.scss'

type LoginDisclaimerProps = {
  justLogin?: boolean
  className?: string
}

export default function LoginDisclaimer({ justLogin = false, className }: LoginDisclaimerProps): JSX.Element {
  const locale = useLocale()

  return (
    <p className={clsx(styles.disclaimer, className)}>
      By {justLogin ? '' : 'creating an account or '}logging in you agree to our{' '}
      <a href={`/${locale}/terms`} target="_blank">
        Terms
      </a>
      ,{' '}
      <a href={`/${locale}/privacy#cookies`} target="_blank">
        Cookies
      </a>
      , and{' '}
      <a href={`/${locale}/privacy`} target="_blank">
        Privacy Policy
      </a>
    </p>
  )
}
