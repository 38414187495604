import { Dispatch, SetStateAction, useEffect } from 'react'

import { PasswordInput } from '@components/inputs/chat-input'
import Input from '@components/inputs/input'

import styles from './password-creator.module.scss'

type PasswordCreatorProps = {
  password: string
  setPassword: Dispatch<SetStateAction<string>>
  variant: 'default' | 'chat'
  setIsPasswordValid: Dispatch<SetStateAction<boolean>>
  disabled?: boolean
}

export default function PasswordCreator({
  variant,
  password,
  setPassword,
  setIsPasswordValid,
  disabled,
}: PasswordCreatorProps): JSX.Element {
  const setPsw = (psw: string): void => {
    setPassword(psw)
  }

  useEffect(() => {
    if (/[A-Z]/.test(password) && /[a-z]/.test(password) && /[0-9]/.test(password) && password.length >= 8) {
      setIsPasswordValid(true)
      return
    }
    setIsPasswordValid(false)
  }, [setIsPasswordValid, password])

  return (
    <>
      {variant === 'chat' ? (
        <PasswordInput
          placeholder="enter a password"
          disabled={disabled}
          type="password"
          value={password}
          onChange={(e): void => setPsw(e.target.value)}
          errorMessage="Please provide a safer password"
          // showError={showError}
        />
      ) : (
        <Input
          placeholder="Enter a new password"
          type="password"
          value={password}
          onChange={(e): void => setPsw(e.target.value)}
          disabled={disabled}
        />
      )}
      <ul className={variant === 'chat' ? styles['chat-specs'] : ''}>
        <p style={{ textAlign: 'left' }}>Your password must contain:</p>
        <li className={/[A-Z]/.test(password) ? styles.valid : ''}>An uppercase character</li>
        <li className={/[a-z]/.test(password) ? styles.valid : ''}>A lowercase character</li>
        <li className={/[0-9]/.test(password) ? styles.valid : ''}>A number</li>
        <li className={password.length >= 8 ? styles.valid : ''}>At least 8 characters</li>
      </ul>
    </>
  )
}
