import { Suspense } from 'react'

import { useRouter, useSearchParams } from 'next/navigation'

import type { LoginProviders } from '@types'

import SocialLoginButtons from '@components/buttons/social-login-buttons'
import LoginDisclaimer from '@components/login-disclaimer'
import useLocale from '@hooks/client/useLocale'

import Wrapper from '../../../wrapper'
import Form from './components'

type EmailLoginProps = {
  setProvider: (prov: LoginProviders) => void
  toggleReset: () => void
  toggleOneTimeCode: (email: string) => void
}

export default function EmailLogin({ setProvider, toggleReset, toggleOneTimeCode }: EmailLoginProps): JSX.Element {
  const query = useSearchParams()
  const router = useRouter()
  const country = useLocale()

  return (
    <Wrapper>
      <Suspense>
        <Form setProvider={setProvider} toggleReset={toggleReset} toggleOneTimeCode={toggleOneTimeCode} />
      </Suspense>
      <LoginDisclaimer />
      <p>Or login with one of these methods</p>
      <SocialLoginButtons
        callback={(): void => {
          router.refresh()
          router.replace(`/${country}${query?.get('origin') ?? '/jobs'}`)
        }}
        isLogin
      />
    </Wrapper>
  )
}
