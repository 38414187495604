'use client'

import { FormEvent, useState } from 'react'

import { useRouter, useSearchParams } from 'next/navigation'

import type { Profile, RestResponse } from '@types'

import { toast } from 'react-toastify'

import Button from '@components/buttons/button'
import PasswordCreator from '@components/inputs/password-creator'
import useLocale from '@hooks/client/useLocale'
import useCookieConsent from '@hooks/useCookiesConsent'
import useHttpRequest from '@hooks/useHttpRequest'
import useLogin from '@hooks/user/useLogin'
import { loginResetPasswordEvent } from '@lib/analytics-events'

import Wrapper from '../wrapper'

export default function UpdatePasswordForm(): JSX.Element {
  const { post } = useHttpRequest()
  const login = useLogin()
  const [password, setPassword] = useState<string>('')
  const [isPasswordValid, setIsPasswordValid] = useState<boolean>(false)
  const [disableForm, setDisableForm] = useState<boolean>(false)
  const router = useRouter()
  const country = useLocale()
  const query = useSearchParams()
  const email = query?.get('email')
  const verificationCode = query?.get('verification_code')
  const [, setCookiesConsent] = useCookieConsent()

  const submit = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault()
    setDisableForm(true)

    if (!isPasswordValid) {
      return
    }

    const setPsw = await post<RestResponse<Profile>>('auth/jobseeker/reset/password', {
      body: {
        password,
        email,
        verificationCode,
      },
      headers: { 'Content-Type': 'application/json' },
    })

    if (setPsw?.status) {
      loginResetPasswordEvent('login_page')
      setCookiesConsent(true)
      login(setPsw.data)
        .then(() => {
          router.push(`/${country}/jobs`)
          toast('Password successfully updated.')
        })
        .catch(() => {
          toast('Something went wrong, please try in a few minutes')
        })
    } else {
      toast(setPsw?.errorMessage)
    }
    setDisableForm(false)
  }

  return (
    <Wrapper>
      <form onSubmit={submit}>
        <PasswordCreator
          password={password}
          setPassword={setPassword}
          setIsPasswordValid={setIsPasswordValid}
          variant="default"
        />
        <Button variant="blue" size="full" disabled={!isPasswordValid || disableForm} type="submit">
          Update password
        </Button>
      </form>
    </Wrapper>
  )
}
