import { FormEvent, useEffect, useState } from 'react'

import { useRouter, useSearchParams } from 'next/navigation'

import type { LoginProviders, Profile, RestResponse } from '@types'

import Button from '@components/buttons/button'
import Input from '@components/inputs/input'
import useLocale from '@hooks/client/useLocale'
import useCookiesConsent from '@hooks/useCookiesConsent'
import useHttpRequest from '@hooks/useHttpRequest'
import useLogin from '@hooks/user/useLogin'
import useTrackLoginEvent from '@hooks/user/useTrackLoginEvent'
import { loginEmailSetEvent, loginOTPSentEvent } from '@lib/analytics-events'
import { validateEmail } from '@lib/helpers/client'

import OneTimeCodeBtn from './components'

type FormProps = {
  setProvider: (prov: LoginProviders) => void
  toggleReset: () => void
  toggleOneTimeCode: (email: string) => void
}
export default function Form({ setProvider, toggleReset, toggleOneTimeCode }: FormProps): JSX.Element {
  const trackLoginEvent = useTrackLoginEvent()
  const [showOneTimeCodeBtn, setShowOneTimeCodeBtn] = useState<boolean>(true)
  const [, setCookiesConsent] = useCookiesConsent()
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [emailErrorMessage, setEmailErrorMessage] = useState<string>('')
  const [showPasswordError, setShowPasswordError] = useState<boolean>(false)
  const [disableForm, setDisabledForm] = useState<boolean>(false)
  const { get, post } = useHttpRequest()
  const login = useLogin()
  const query = useSearchParams()
  const router = useRouter()
  const country = useLocale()

  const submit = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault()

    if (!validateEmail(email) || !password) {
      setEmailErrorMessage(!validateEmail(email) ? 'Please set a valid email address' : '')
      setShowPasswordError(!password)
      return
    }

    setDisabledForm(true)
    setEmailErrorMessage('')
    setShowPasswordError(false)

    const message = await get<RestResponse<{ provider: LoginProviders }>>(
      `auth/jobseeker/provider?email=${encodeURIComponent(email)}`,
    )

    if (message?.status) {
      loginEmailSetEvent(message?.data?.provider.toString() || '')
      setProvider(message?.data?.provider)

      // TODO: What about a new user?
      if (message?.data?.provider === 'SONIC_PASSWORD') {
        const sendPassword = await post<RestResponse<Profile>>('auth/jobseeker/password', {
          body: {
            email,
            password,
          },
        })

        if (sendPassword?.status) {
          await login(sendPassword.data)
          trackLoginEvent(sendPassword.data, 'Password')
          setCookiesConsent(true)
          router.refresh()
          router.replace(`/${country}${query?.get('origin') ?? '/jobs'}`)
        } else {
          setShowPasswordError(true)
        }
      }
    }
    setDisabledForm(false)
  }

  const submitOneTimeCode = async (): Promise<void> => {
    const prov = await get<RestResponse<{ provider: LoginProviders }>>(
      `auth/jobseeker/provider?email=${encodeURIComponent(email)}`,
    )

    if (prov?.status) {
      if (prov.data.provider === 'NA') {
        setEmailErrorMessage('We couldn’t find a SonicJobs account associated with this email address.')
        setShowPasswordError(false)
        return
      }

      if (['SONIC_PASSWORD', 'SONIC_MAGIC_LINK', 'OTHER', 'OTP'].includes(prov.data.provider)) {
        const sendOtpRequest = await post<RestResponse<unknown>>('auth/jobseeker/send/otp', {
          body: {
            targetId: email,
            deviceType: 'WEB',
            targetMedium: 'EMAIL',
          },
        })

        if (sendOtpRequest?.status) {
          loginOTPSentEvent('login page')
          toggleOneTimeCode(email)
        }
      }

      setProvider(prov.data.provider)
    }
  }

  useEffect(() => {
    setShowOneTimeCodeBtn(validateEmail(email))
  }, [email, setShowOneTimeCodeBtn])

  return (
    <form onSubmit={submit}>
      <h2>Login with your email</h2>
      <Input
        placeholder="Your email address"
        value={email}
        showError={Boolean(emailErrorMessage)}
        errorMessage={emailErrorMessage}
        onChange={(e): void => setEmail(e.target.value)}
        type="text"
      />
      <Input
        type="password"
        placeholder="Password"
        value={password}
        showError={showPasswordError}
        errorMessage="Your email or password is incorrect. Please try again."
        onChange={(e): void => setPassword(e.target.value)}
      />
      <Button type="button" variant="simple-text" size="full" onClick={toggleReset}>
        Forgotten your password?
      </Button>
      <Button type="submit" variant="blue" size="full" disabled={disableForm}>
        Login
      </Button>
      {showOneTimeCodeBtn && <OneTimeCodeBtn onClick={submitOneTimeCode} />}
    </form>
  )
}
