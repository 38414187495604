import(/* webpackMode: "eager" */ "/src/clients/web/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/src/clients/web/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/src/clients/web/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/src/clients/web/packages/website/src/app/[locale]/login/assets/squares.svg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/src/clients/web/packages/website/src/app/[locale]/login/components/action-dispatcher/action-dispatcher.tsx");
;
import(/* webpackMode: "eager" */ "/src/clients/web/packages/website/src/app/[locale]/login/login.module.scss");
;
import(/* webpackMode: "eager" */ "/src/clients/web/packages/website/src/app/[locale]/page-view-event.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/src/clients/web/packages/website/src/components/layout/layout/navbar/links/links.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/src/clients/web/packages/website/src/components/layout/layout/navbar/logo/logo.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/src/clients/web/packages/website/src/components/layout/layout/navbar/profile/profile.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/src/clients/web/packages/website/src/components/layout/layout/navbar/wrapper/wrapper.tsx");
;
import(/* webpackMode: "eager" */ "/src/clients/web/packages/website/src/components/layout/simple-layout/simple-layout.module.scss");
