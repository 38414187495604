import { useIsLoading } from '@stores/isLoading.store'

import { Dispatch, SetStateAction, useCallback } from 'react'

import { AppleResponse, FacebookCallBackParams, Profile, RestResponse } from '@types'

import jwt_decode, { JwtPayload } from 'jwt-decode'

import useCookiesConsent from '@hooks/useCookiesConsent'
import useHttpRequest from '@hooks/useHttpRequest'

import useLogin from './useLogin'

type SocialLoginProps = {
  setLoading?: Dispatch<SetStateAction<boolean>>
  trackLoginEvent?: (data: Profile, method: string) => void
  callback?: () => void
}

type SocialLoginReturn = {
  googleResponse: (response: google.accounts.oauth2.CodeResponse) => Promise<void>
  facebookResponse: (response: FacebookCallBackParams) => Promise<void>
  appleResponse: (response: AppleResponse) => Promise<void>
}

export default function useSocialLogin({ setLoading, trackLoginEvent, callback }: SocialLoginProps): SocialLoginReturn {
  const { post } = useHttpRequest()
  const [, setCookiesConsent] = useCookiesConsent()
  const login = useLogin()
  const setIsLoading = useIsLoading((state) => state.setIsLoading)

  const googleResponse = useCallback(
    async (response: google.accounts.oauth2.CodeResponse): Promise<void> => {
      setIsLoading(true, 'Logging in')
      setLoading?.(true)
      const loginData = await post<RestResponse<Profile>>('auth/google', { service: 'web', body: response })
      if (loginData?.data?.token) {
        await login(loginData.data)
        callback?.()
        setCookiesConsent(true)
        trackLoginEvent?.(loginData.data, 'Google')
        setIsLoading(false)
        setLoading?.(false)
      } else {
        console.error('Login Error. Your google login is invalid', login)
      }
    },
    [setCookiesConsent, post, login, trackLoginEvent, setLoading, callback, setIsLoading],
  )

  const facebookResponse = useCallback(
    async (response: FacebookCallBackParams): Promise<void> => {
      const { authResponse, userData } = response
      if (!('accessToken' in authResponse)) {
        console.error('Login Error. Facebook returned a failure status', response)
        return
      }
      setLoading?.(true)
      const sonicRequest = {
        token: authResponse.accessToken,
        id: userData.id,
        name: userData.name,
        email: userData.email,
        first_name: userData.first_name,
        last_name: userData.last_name,
        channel: 'web',
      }

      const loginData = await post<RestResponse<Profile>>('auth/jobseeker/facebook', { body: sonicRequest })
      if (loginData?.data?.token) {
        await login(loginData.data)
        setCookiesConsent(true)
        trackLoginEvent?.(loginData.data, 'Facebook')
        callback?.()
      } else {
        console.error('Login Error. Your facebook login is invalid', login)
      }
    },
    [setCookiesConsent, post, login, trackLoginEvent, setLoading, callback],
  )

  const appleResponse = useCallback(
    async (response: AppleResponse): Promise<void> => {
      if (!('authorization' in response)) {
        console.error('Login Error. Apple returned a failure status', response)
        return
      }
      setLoading?.(true)
      const decoded = jwt_decode<JwtPayload & { email: string }>(response.authorization.id_token)
      const sonicRequest = {
        token: response.authorization.id_token,
        id: decoded.sub,
        email: response.user?.email || decoded.email,
        first_name: response.user?.name?.firstName,
        last_name: response.user?.name?.lastName,
        channel: 'web',
      }

      const loginData = await post<RestResponse<Profile>>('auth/jobseeker/apple', { body: sonicRequest })
      if (loginData?.data?.token) {
        await login(loginData.data)
        setCookiesConsent(true)
        trackLoginEvent?.(loginData.data, 'Apple')
        callback?.()
      } else {
        console.error('Login Error. Your apple login is invalid', login)
      }
    },
    [setCookiesConsent, post, login, trackLoginEvent, setLoading, callback],
  )

  return { googleResponse, facebookResponse, appleResponse }
}
