'use client'

import { useJobStore } from '@stores/job.store'
import { useSearchStore } from '@stores/search.store'
import { useUserStore } from '@stores/user.store'

import { startTransition } from 'react'

import type { Profile, User } from '@types'

import { useShallow } from 'zustand/react/shallow'

import useLocale from '@hooks/client/useLocale'
import useCookiesConsent from '@hooks/useCookiesConsent'
import useHttpRequest from '@hooks/useHttpRequest'
import { updateMixpanelUser } from '@lib/helpers/client'

import useUpdatePreferences from './useUpdatePreferences'

export default function useLogin(): (userProfile: Profile) => Promise<void> {
  const [setProfile, setTokens, source] = useUserStore(
    useShallow((state) => [state.setProfile, state.setTokens, state.source]),
  )

  const locale = useLocale()

  const { put } = useHttpRequest()

  const microRole = useSearchStore((state) => state.search.job.micro)
  const job = useJobStore((st) => st.job)
  const updateSearch = useUpdatePreferences()
  const [areCookiesAccepted, setCookiesConsent] = useCookiesConsent()

  const sendUserSearchData = (profile: Profile): void => {
    const { token, user } = profile

    put<Promise<User>>('jobseeker', {
      body: { ...user, originSource: source, microRole: microRole || job?.microRole || '', scope: job?.scope || '' },
      headers: {
        'AUTH-TOKEN': token,
      },
    })
  }

  // TODO: move here useTrackLogin()
  const login = async (userProfile: Profile): Promise<void> => {
    const { user, profileType, token, refreshToken } = userProfile
    if (!areCookiesAccepted) {
      setCookiesConsent(true)
    }

    document.cookie = `${locale}-sonic-user=${token}; max-age=${1 * 60 * 60}; path=/; secure; samesite=none`
    document.cookie = `${locale}-sonic-refresh=${refreshToken}; max-age=${
      1 * 30 * 24 * 60 * 60
    }; path=/; secure; samesite=none`
    document.cookie = `${locale}-soft-login=; expires=${new Date(0).toUTCString()}; path=/`

    startTransition(() => {
      setTokens({
        [`${locale}-sonic-user`]: token,
        [`${locale}-sonic-refresh`]: refreshToken,
      })
    })

    updateMixpanelUser(user.id, user.email, user.firstName, user.lastName)
    updateSearch(userProfile.token, undefined, true)

    if (!user.eligibilityToWork) {
      user.eligibilityToWork = true
    }

    if (profileType === 'NEW') {
      sendUserSearchData(userProfile)
    }

    startTransition(() => {
      setProfile({ user })
    })
  }
  return login
}
