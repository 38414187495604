export default function capitalizeFirstLetter(value: string, allWords = false): string {
  if (allWords) {
    const IGNORE_CAPITAL = ['of', 'and', '/']
    const FULL_CAPITAL = ['qa', '3d']
    return (
      value
        // Split on empty spaces and slashes
        ?.split(/( |\/)/g)
        ?.map((word) => {
          if (IGNORE_CAPITAL.includes(word)) {
            return word
          }

          if (FULL_CAPITAL.includes(word)) {
            return word.toUpperCase()
          }

          return word.charAt(0).toUpperCase() + word.slice(1)
        })
        ?.join('')
    )
  }
  return value.charAt(0).toUpperCase() + value.slice(1)
}
