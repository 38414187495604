import { Dispatch, SetStateAction } from 'react'

import dynamic from 'next/dynamic'

import type { LoginProviders } from '@types'

import useSocialLogin from '@hooks/user/useSocialLogin'
import useTrackLoginEvent from '@hooks/user/useTrackLoginEvent'

const AppleLogin = dynamic(() => import('./apple-login'), { ssr: false })
const FacebookLogin = dynamic(() => import('./facebook-login'), { ssr: false })
const GoogleLogin = dynamic(() => import('./google-login'), { ssr: false })

type SocialLoginButtonsProps = {
  provider?: LoginProviders
  callback?: () => void
  isLogin?: boolean
  setLoading?: Dispatch<SetStateAction<boolean>>
}

const SocialLoginButtons = ({ provider, callback, isLogin, setLoading }: SocialLoginButtonsProps): JSX.Element => {
  const trackLoginEvent = useTrackLoginEvent()

  const { googleResponse, facebookResponse, appleResponse } = useSocialLogin({ callback, trackLoginEvent, setLoading })

  if (provider === 'APPLE') {
    return <AppleLogin callback={appleResponse} isLogin={isLogin} />
  }

  if (provider === 'FACEBOOK') {
    return <FacebookLogin callback={facebookResponse} isLogin={isLogin} />
  }

  if (provider === 'GOOGLE') {
    return <GoogleLogin callback={googleResponse} isLogin={isLogin} />
  }

  return (
    <>
      <GoogleLogin callback={googleResponse} isLogin={isLogin} />
      <AppleLogin callback={appleResponse} isLogin={isLogin} />
      <FacebookLogin callback={facebookResponse} isLogin={isLogin} />
    </>
  )
}

export default SocialLoginButtons
