'use client'

import type { ReactNode } from 'react'

import styles from './wrapper.module.scss'

type BaseFormProps = {
  children: ReactNode
}
export default function BaseForm({ children }: BaseFormProps): JSX.Element {
  return <div className={styles.wrapper}>{children}</div>
}
