import { useRouter, useSearchParams } from 'next/navigation'

import { LoginProviders } from '@types'

import Button from '@components/buttons/button'
import SocialLoginButtons from '@components/buttons/social-login-buttons'
import useLocale from '@hooks/client/useLocale'
import { capitalizeFirstLetter } from '@lib/helpers/client'

import Wrapper from '../../../wrapper'

type UserWithProviderProps = {
  provider: LoginProviders
  back: () => void
}

export default function UserWithProvider({ provider, back }: UserWithProviderProps): JSX.Element {
  const formattedProvider = capitalizeFirstLetter(provider.toLowerCase())
  const query = useSearchParams()
  const router = useRouter()
  const country = useLocale()

  return (
    <>
      <Wrapper>
        <h2>Login with {formattedProvider}</h2>
        <p>It looks like this email address is associated with a {formattedProvider} login for SonicJobs.</p>
        <SocialLoginButtons
          provider={provider}
          callback={(): void => {
            router.refresh()
            router.replace(`/${country}${query?.get('origin') ?? '/jobs'}`)
          }}
        />
      </Wrapper>
      <Button type="button" variant="simple-text" onClick={back}>
        Back to login
      </Button>
    </>
  )
}
