export default function updateMixpanelUser(id = '', email = '', firstName = '', lastName = ''): void {
  window.mixpanel?.identify?.(id)
  window.mixpanel?.people?.set_once({
    distinct_id: id,
    email,
  })
  window.mixpanel?.register({
    jobSeekerId: id,
    distinct_id: id,
  })
  window?.gtag?.('set', 'user_properties', {
    distinct_id: id,
    email,
  })
  if (firstName || lastName) {
    window.mixpanel?.people?.set({
      name: `${firstName} ${lastName}`,
    })
  }
}
