import Image from 'next/image'

import Button from '@components/buttons/button'

import EnvelopeIcon from '@images/login/envelope.svg'

import styles from './one-time-code-btn.module.scss'

type OneTimeCodeBtnProps = {
  onClick: () => void
}

export default function OneTimeCodeBtn({ onClick }: OneTimeCodeBtnProps): JSX.Element {
  return (
    <Button size="full" variant="secondary" type="button" className={styles.btn} onClick={onClick}>
      <Image src={EnvelopeIcon} alt="envelope icon" className={styles.icon} />
      Send me a one-time login code
    </Button>
  )
}
