'use client'

import styles from './header.module.scss'

type HeaderProps = {
  title: string
  subtitle?: string
}

export default function Header({ title, subtitle }: HeaderProps): JSX.Element {
  return (
    <>
      <h1 className={styles.h1} dangerouslySetInnerHTML={{ __html: title }} />
      {subtitle && <h2 className={styles.h2}>{subtitle}</h2>}
    </>
  )
}
