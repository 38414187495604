'use client'

import { Suspense, useState } from 'react'

import { useSearchParams } from 'next/navigation'

import { useToggle } from 'react-use'

import { Header, LoginForm, OneTimeCode, ResetForm, UpdatePasswordForm } from './components'

export default function ActionDispatcher(): JSX.Element {
  const [showReset, setShowReset] = useToggle(false)
  const [showOneTimeCode, setShowOneTimeCode] = useState<string>('')
  const query = useSearchParams()
  const isResetPassword = Boolean(query?.get('verification_code') && query?.get('email'))

  // TODO: Split this component into different pages like: /login, /reset-password, /new-password, /one-time-code-login
  if (showOneTimeCode) {
    return (
      <>
        <Header title={`Code sent to ${showOneTimeCode}<br>Please enter below`} />
        <OneTimeCode email={showOneTimeCode} backToLogin={(): void => setShowOneTimeCode('')} />
      </>
    )
  }
  if (isResetPassword) {
    return (
      <>
        <Header title="Enter a new password" />
        <Suspense>
          <UpdatePasswordForm />
        </Suspense>
      </>
    )
  }

  if (showReset) {
    return (
      <>
        <Header title="Reset your password" subtitle="We’ll send you an email to reset your password" />
        <ResetForm toggleReset={setShowReset} />
      </>
    )
  }

  return (
    <>
      <Header title="Login to your account" />
      <LoginForm toggleReset={setShowReset} toggleOneTimeCode={setShowOneTimeCode} />
    </>
  )
}
