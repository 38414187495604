import { useCallback } from 'react'

import { usePathname } from 'next/navigation'

import { Profile } from '@types'

import useLocale from '@hooks/client/useLocale'
import { loginSignInEvent, loginSignUpEvent } from '@lib/analytics-events'
import { readLocalStorageValue } from '@lib/helpers/client'

export default function useTrackLoginEvent(): (data: Profile, method: string) => void {
  const pathname = usePathname()
  const locale = useLocale()

  return useCallback(
    async (data: Profile, method: string) => {
      const mixPanelModule = await import('mixpanel-browser')
      const mixpanel = mixPanelModule.default
      const source = pathname === `${locale}/login` ? 'login_page' : 'chat'
      if (data.profileType === 'NEW') {
        mixpanel.alias(data.user.id, readLocalStorageValue('sonicUuid') || '')
        loginSignUpEvent(data.user.id, source, method)
      } else {
        loginSignInEvent(data.user.id, source, method)
      }
    },
    [pathname, locale],
  )
}
