import Image from 'next/legacy/image'

import { useToggle } from 'react-use'

import Button from '@components/buttons/button'

import EyeHiddenIcon from '@images/chat/eye-hidden.svg'
import EyeIcon from '@images/chat/eye.svg'

import ChatInput, { InputProps } from './chat-input'

import styles from './chat-input.module.scss'

export default function PasswordInput(props: InputProps): JSX.Element {
  const [showPassword, setShowPassword] = useToggle(false)

  return (
    <div className={styles['password-container']}>
      <ChatInput {...props} type={showPassword ? 'text' : 'password'} />
      <Button onClick={setShowPassword}>
        <Image src={showPassword ? EyeHiddenIcon : EyeIcon} alt="Eye icon" layout="fixed" width={20} height={20} />
      </Button>
    </div>
  )
}
