import { InputHTMLAttributes } from 'react'

import styles from './chat-input.module.scss'

type InputProps = InputHTMLAttributes<HTMLInputElement> & {
  showError?: boolean
  errorMessage?: string
  label?: string
}

export default function ChatInput(props: InputProps): JSX.Element {
  const { showError, errorMessage, label, ...rest } = props
  return (
    <label htmlFor={props.id}>
      {label && <p className={styles.label}>{label}</p>}
      <input {...rest} className={`${styles.input} ${props.className ?? ''}`} autoComplete="off" />
      {showError && <p className={styles.error}>{errorMessage}</p>}
    </label>
  )
}

export type { InputProps }
