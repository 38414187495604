'use client'

import { useRouter } from 'next/navigation'

import Button from '@components/buttons/button'
import useLocale from '@hooks/client/useLocale'
import { miscRedirectToSearchPageEvent } from '@lib/analytics-events'

import Wrapper from '../../../wrapper'

type NewUserProps = {
  back: () => void
}

export default function NewUser({ back }: NewUserProps): JSX.Element {
  const router = useRouter()
  const country = useLocale()
  const moveToSearch = (): void => {
    miscRedirectToSearchPageEvent()
    router.push(`/${country}/jobs`)
  }

  return (
    <>
      <Wrapper>
        <h2>Welcome!</h2>
        <p>Ready for 1 tap apply and the perfect job? Start your search and apply for a job, to create your profile.</p>
        <Button type="button" variant="blue" size="full" onClick={moveToSearch}>
          Search
        </Button>
      </Wrapper>
      <Button type="button" variant="simple-text" onClick={back}>
        Back to login
      </Button>
    </>
  )
}
