'use client'

import { FormEvent, useEffect, useState } from 'react'

import { useRouter } from 'next/navigation'

import Button from '@components/buttons/button'
import Input from '@components/inputs/input'
import useForgotPassword from '@hooks/user/useForgotPassword'
import { validateEmail } from '@lib/helpers/client'

import Wrapper from '../wrapper'

type LoginFormProps = {
  toggleReset: () => void
}

export default function ResetPasswordForm({ toggleReset }: LoginFormProps): JSX.Element {
  const [email, setEmail] = useState<string>('')
  const [isEmailValid, setIsEmailValid] = useState<boolean>(validateEmail(email))
  const [showEmailError, setShowEmailError] = useState<boolean>(false)
  const [isEmailSent, setIsEmailSent] = useState<boolean>(false)
  const [disableForm, setDisableForm] = useState<boolean>(false)
  const forgotPassword = useForgotPassword({ callback: () => setIsEmailSent(true) })
  const router = useRouter()

  // TODO: refactor backend in order to remove this useEffect
  useEffect(() => {
    router.push(`?reset=true`)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setIsEmailValid(validateEmail(email))
  }, [email])

  const submit = (e: FormEvent<HTMLFormElement>): void => {
    e.preventDefault()
    setDisableForm(true)
    if (!isEmailValid) {
      setShowEmailError(true)
      return
    }
    forgotPassword(email).finally(() => setDisableForm(false))
    setShowEmailError(false)
  }

  return (
    <>
      <Wrapper>
        {isEmailSent ? (
          <>
            <h2>Email sent!</h2>
            <p>Please check your inbox to reset your password</p>
            <Button href="/" variant="simple-text" size="full">
              Go to the homepage
            </Button>
          </>
        ) : (
          <form onSubmit={submit}>
            <Input
              placeholder="Your email address"
              value={email}
              onChange={(e): void => setEmail(e.target.value)}
              showError={showEmailError}
              errorMessage="Please set a valid email address"
            />
            <Button type="submit" variant="blue" size="full" disabled={!isEmailValid || disableForm}>
              Reset my password
            </Button>
          </form>
        )}
      </Wrapper>
      <Button type="button" variant="simple-text" onClick={toggleReset}>
        Back to login
      </Button>
    </>
  )
}
