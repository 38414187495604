'use client'

import { ChangeEvent, FormEvent, useEffect, useState } from 'react'

import { useRouter } from 'next/navigation'

import { Profile, RestResponse } from '@types'

import { toast } from 'react-toastify'

import Button from '@components/buttons/button/button'
import Input from '@components/inputs/input'
import useLocale from '@hooks/client/useLocale'
import useHttpRequest from '@hooks/useHttpRequest'
import useLogin from '@hooks/user/useLogin'
import { loginOTPEnteredEvent, loginOTPSentAgainEvent, loginSignInEvent } from '@lib/analytics-events'

import Wrapper from '../wrapper'

type OneTimeCodeProps = {
  email: string
  backToLogin: () => void
}

export default function OneTimeCode({ backToLogin, email }: OneTimeCodeProps): JSX.Element {
  const [disabled, setDisabled] = useState<boolean>(true)
  const [showError, setShowError] = useState<boolean>(false)
  const [code, setCode] = useState<string>('')
  const { post } = useHttpRequest()
  const router = useRouter()
  const login = useLogin()
  const country = useLocale()

  useEffect(() => {
    setDisabled(code.length !== 6)
    if (code.length === 6) {
      loginOTPEnteredEvent('login page')
    }
  }, [code, setDisabled])

  const set6DigitCode = (e: ChangeEvent<HTMLInputElement>): void => {
    if (e.target.value.length < 7) {
      setCode(e.target.value)
    }
  }

  const resendCode = async (): Promise<void> => {
    const sendOtpRequest = await post<RestResponse<unknown>>('auth/jobseeker/send/otp', {
      body: {
        targetId: email,
        deviceType: 'WEB',
        targetMedium: 'EMAIL',
      },
    })
    if (sendOtpRequest?.status) {
      loginOTPSentAgainEvent('login page')
      toast(`Code sent to ${email}`)
    }
  }

  const onSubmit = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault()
    setDisabled(true)
    setShowError(false)
    const checkOneTimeCode = await post<RestResponse<Profile>>('auth/jobseeker/otp', {
      body: {
        email,
        code,
        channel: 'web',
      },
    })

    if (checkOneTimeCode?.status) {
      loginSignInEvent(checkOneTimeCode.data.user.id, 'login page', 'otp')
      await login(checkOneTimeCode.data)
      router.push(`/${country}/jobs`)
    } else {
      setShowError(true)
    }
    setDisabled(false)
  }
  return (
    <>
      <Wrapper>
        <form onSubmit={onSubmit}>
          <h2>6-digit code</h2>
          <Input
            type="number"
            value={code}
            onChange={set6DigitCode}
            placeholder="Enter your code"
            showError={showError}
            errorMessage="This code has expired"
            removeNumberArrows
          />
          <Button type="button" variant="simple-text" onClick={resendCode}>
            Resend code
          </Button>
          <p>The code will be valid for 15 minutes. Check spam folders in case you don’t see the email.</p>
          <Button variant="blue" size="full" disabled={disabled}>
            Login
          </Button>
        </form>
      </Wrapper>
      <Button variant="simple-text" onClick={backToLogin}>
        Back to login
      </Button>
    </>
  )
}
