import { useCallback } from 'react'

import type { RestResponse } from '@types'

import { toast } from 'react-toastify'

import useHttpRequest from '@hooks/useHttpRequest'

type ForgotPasswordProps = {
  callback?: () => void
  errorCallback?: () => void
}

export default function useForgotPassword({
  callback,
  errorCallback,
}: ForgotPasswordProps): (email: string) => Promise<void> {
  const { post } = useHttpRequest()

  return useCallback(
    async (email: string) => {
      const sendEmail = await post<RestResponse<string>>('auth/jobseeker/reset/send/email', {
        body: {
          email,
          callBackUrl: window?.location?.href ?? null,
          deviceType: 'WEB',
        },
      })

      if (sendEmail?.status) {
        callback?.()
      } else {
        toast(sendEmail?.errorMessage, { type: toast.TYPE.ERROR, autoClose: 1500 })
        errorCallback?.()
      }
    },
    [post, callback, errorCallback],
  )
}
