'use client'

import { Suspense, useState } from 'react'

import type { LoginProviders } from '@types'

import { EmailLogin, NewUser, UserWithProvider } from './components'

type LoginFormProps = {
  toggleReset: () => void
  toggleOneTimeCode: (email: string) => void
}

export default function LoginForm({ toggleReset, toggleOneTimeCode }: LoginFormProps): JSX.Element {
  const [provider, setProvider] = useState<LoginProviders | undefined>(undefined)

  if (!provider || ['SONIC_PASSWORD', 'SONIC_MAGIC_LINK', 'OTHER', 'OTP'].includes(provider)) {
    return (
      <Suspense>
        <EmailLogin setProvider={setProvider} toggleReset={toggleReset} toggleOneTimeCode={toggleOneTimeCode} />
      </Suspense>
    )
  }

  if (provider === 'NA') {
    return <NewUser back={(): void => setProvider(undefined)} />
  }

  return (
    <Suspense>
      <UserWithProvider provider={provider} back={(): void => setProvider(undefined)} />
    </Suspense>
  )
}
