import { InputHTMLAttributes, useMemo } from 'react'

import Image from 'next/image'

import { useToggle } from 'react-use'

import Button from '@components/buttons/button'

import EyeIcon from '@images/login/eye.svg'

import styles from './input.module.scss'

type InputProps = InputHTMLAttributes<HTMLInputElement> & {
  showError?: boolean
  errorMessage?: string
  removeNumberArrows?: boolean
}

export default function Input({
  showError,
  errorMessage,
  id,
  removeNumberArrows,
  ...htmlAttributes
}: InputProps): JSX.Element {
  const { type, className } = htmlAttributes
  const [showPassword, togglePassword] = useToggle(false)

  const classes = useMemo((): string => {
    const baseClass = [showError && styles['input-error'], className, styles.input]
    if (removeNumberArrows && type === 'number') {
      baseClass.push(styles['remove-number-arrows'])
    }

    return baseClass.filter(Boolean).join(' ')
  }, [className, removeNumberArrows, type, showError])

  return (
    <label htmlFor={id} className={styles.wrap} data-testid="input-label">
      <input
        {...htmlAttributes}
        className={classes}
        autoComplete="off"
        type={showPassword ? 'text' : type}
        data-testid="input"
      />
      {type === 'password' && (
        <Button type="button" data-testid="password-button">
          <Image src={EyeIcon} alt="eye icon" onClick={(): void => togglePassword()} width={16} height={12} />
        </Button>
      )}
      {showError && <p className={styles.error}>{errorMessage}</p>}
    </label>
  )
}

export type { InputProps }
