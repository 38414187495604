/* eslint-disable import/prefer-default-export */
import type { JobPageItem, TermsConditions } from '@types'

import { create } from 'zustand'
import { devtools } from 'zustand/middleware'

type JobStore = {
  job?: JobPageItem
  termsAndConditions: TermsConditions[]
  isApplied: boolean
  isGoogleCrawler: boolean
  isEasyApply: boolean
  setIsApplied: (val: boolean) => void
  isLinkedin: boolean
  isEligibleToApply: boolean
  initializeStore: ({
    job,
    tc,
    isEasyApply,
    isEligibleToApply,
    isLinkedin,
    postApplyMessage,
  }: {
    job: JobPageItem
    tc: TermsConditions[] | null
    isEasyApply?: boolean
    isEligibleToApply: boolean
    isLinkedin: boolean
    postApplyMessage?: string | null
  }) => void
  clearStore: () => void
  postApplyMessage: string | null
}

export const useJobStore = create<JobStore>()(
  devtools((set) => ({
    job: undefined,
    isApplied: false,
    isGoogleCrawler: false,
    termsAndConditions: [],
    isLinkedin: false,
    isEasyApply: false,
    isEligibleToApply: true,
    postApplyMessage: null,
    setIsApplied: (isApplied): void => set({ isApplied }),
    initializeStore: ({
      job,
      tc,
      isEligibleToApply,
      isEasyApply = false,
      isLinkedin = false,
      postApplyMessage = null,
    }): void =>
      set({
        job,
        termsAndConditions: tc || [],
        isApplied: job.hasApplied,
        isEasyApply,
        isEligibleToApply,
        isLinkedin,
        postApplyMessage,
      }),
    clearStore: (): void =>
      set({
        job: undefined,
        isApplied: false,
        termsAndConditions: [],
        isEasyApply: false,
        isLinkedin: false,
        postApplyMessage: null,
      }),
  })),
)
